// variables to make primeng components look more Bulma-ish
$fontFamily: $family-primary;
$fontSize: $size-normal;
$borderRadius: $radius;

$disabledOpacity: 0.35;

//Header
$headerBorderWidth: 1px;
$headerBorderColor: $primary;
$headerBgColor: $primary;
$headerTextColor: $primary-invert;
$headerFontWeight: bold;
$headerIconTextColor: $primary-invert;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: $border;
$contentBgColor: $background;
$contentTextColor: $text;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: $link;
$stateDefaultBgColor: $link-invert;
$stateDefaultTextColor: $link;

//Active State
$stateActiveBorderColor: $primary;
$stateActiveBgColor: $primary;
$stateActiveTextColor: $primary-invert;

//Focus State
$stateFocusBorderColor: $primary;
$stateFocusBgColor: $primary-invert;
$stateFocusTextColor: $primary;

//Error State
$stateErrorBorderColor: $danger;
$stateErrorBgColor: $danger;
$stateErrorTextColor: $danger-invert;

//Highlight State
$stateHighlightBorderColor: $yellow;
$stateHighlightBgColor: $yellow;
$stateHighlightTextColor: #000000;

//Hover State
$stateHoverBorderColor: lighten($primary, 20%);
$stateHoverBgColor: lighten($primary, 20%);
$stateHoverTextColor: lighten($primary-invert, 20%);

//Forms
$inputBgColor: $text-invert;
$inputTextColor: $text;
$invalidInputBorderColor: $danger;
$inputGroupTextColor: $text;

.p-table.bulma {
    table {
        border-collapse: collapse;
    }

    %cell {
        background: $white;
        border: $table-cell-border;
        border-width: $table-cell-border-width;
        color: $table-color;
        padding: $table-cell-padding;
    }

    .p-table-thead > tr {
        > th,
        > td {
            @extend %cell;

            border-width: $table-head-cell-border-width;
            color: $table-head-cell-color;
        }
    }

    .p-table-tbody > tr {
        > th,
        > td {
            @extend %cell;
        }
    }

    .p-table-tfoot > tr {
        > th,
        > td {
            @extend %cell;

            border-width: $table-foot-cell-border-width;
            color: $table-foot-cell-color;
        }
    }

    &.is-bordered {
        table {
            border: $table-cell-border;
        }

        table.p-table-scrollable-header-table {
            border: none;

            .p-table-thead > tr > {
                td, th {
                    border-bottom: none;
                }
            }
        }

        .p-table-thead > tr,
        .p-table-tbody > tr,
        .p-table-tfoot > tr {
            > th,
            > td {
                border-width: 1px;
            }

            &:last-child {
                > th,
                > td {
                    border-bottom-width: 1px;
                }
            }
        }
    }

    &:not(.is-bordered) {
        table {
            border: none;
        }

        .p-table-tbody > tr:last-child {
            > td,
            > th {
                border-bottom-width: 0;
            }
        }
    }

    &.is-striped {
        .p-table-tbody > tr:nth-child(even) {
            > td, > th {
                background-color: $table-striped-row-even-background-color;
            }
        }

        &.is-hoverable .p-table-tbody > tr:nth-child(even):hover {
            > td, > th {
                background-color: $table-striped-row-even-hover-background-color;
            }
        }

        &:not(.is-hoverable) .p-table-tbody > tr:nth-child(even):hover {
            > td, > th {
                background-color: $table-striped-row-even-background-color;
            }
        }
    }

    &:not(.is-striped) {
        .p-table-tbody > tr:nth-child(even) {
            > td, > th {
                background-color: $table-background-color;
            }
        }
    }

    &.is-hoverable {
        .p-table-tbody > tr:hover {
            > td, > th {
                background-color: $table-row-hover-background-color;
            }
        }
    }
}

.p-tooltip > .p-tooltip-text {
    width: 500px;
    max-width: max-content;
  }

.p-slider {
    .p-slider-range {
        background: $primary;
    }

    .p-slider-handle {
        background: $secondary;
        border-color: $secondary;
        color: $secondary;
    }
}

.p-widget-content a.p-overlaypanel-close,
.p-overlaypanel .p-overlaypanel-close,
.p-overlaypanel .p-overlaypanel-close:enabled {
    @include closeButton;
}

// $table-head-cell-color	$text-strong
// $table-foot-cell-border-width	2px 0 0
// $table-foot-cell-color	$text-strong
// $table-row-active-background-color	$primary
// $table-row-active-color	$primary-invert

@import "~primeng/resources/themes/nova/theme.css";
